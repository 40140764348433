import styled from 'styled-components';
import React from 'react';

const UnstyledNotFound = ({ className }: { className?: string }) => (
  <div className={className}>nothing found</div>
);

const NotFound = styled(UnstyledNotFound)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default NotFound;
