import { Outlet, Link } from 'react-router-dom';
import styled from 'styled-components';
import React from 'react';
import sardies from '../images/sardies.png';
import glitchSardies from '../images/WIH/sardies.jpeg';

const NavItem = styled.div<{ glitch?: boolean }>`
  a {
    color: black;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    span,
    img {
      border-bottom: 3px solid
        ${(props) => (props.glitch ? 'rgb(185,187,189)' : 'white')};
    }

    &:hover {
      span {
        border-bottom: 3px solid
          ${(props) => (props.glitch ? 'red' : '#39738f')};
      }
    }
  }

  margin: 10px;
  font-size: 24px;
  font-weight: 400;

  &:first-child {
    margin-right: 0;
  }
`;

const NavUnstyled = ({
  className,
  glitch,
}: {
  className?: string;
  glitch?: boolean;
}) => (
  <div className={className}>
    <NavItem glitch={glitch}>
      <Link to={'/'}>
        <img src={glitch ? glitchSardies : sardies} className={'icon'} />
      </Link>
    </NavItem>
    <NavItem glitch={glitch}>
      <a href={'#schedule'}>
        <span>Schedule</span>
      </a>
    </NavItem>
    {glitch && (
      <NavItem glitch={glitch}>
        <a href={'https://weatherishappening.com/'} target="#">
          <span>WEATHER</span>
        </a>
      </NavItem>
    )}
  </div>
);

const Nav = styled(NavUnstyled)`
  display: flex;
  align-items: center;
  justify-content: left;
  position: fixed;
  height: 55px;
  background: ${(props) => (props.glitch ? 'rgb(185,187,189)' : 'white')};
  width: 100%;
  z-index: 1;

  .icon {
    width: 33px;
    height: fit-content;
    margin: 5px;
    margin-right: 15px;
  }
`;

const Spacer = styled.div`
  height: 100px;
`;

const AppUnstyled = ({
  className,
  glitch,
}: {
  className?: string;
  glitch?: boolean;
}) => {
  return (
    <div className={className}>
      <Nav glitch={glitch} />
      <div className={'container'}>
        <Spacer />
        <Outlet />
      </div>
    </div>
  );
};

const App = styled(AppUnstyled)`
  font-family: ${(props) =>
    props.glitch ? 'Arial' : "'Overpass', Sans-Serif"};

  color: ${(props) => (props.glitch ? 'red' : 'black')};
  ${(props) => props.glitch && 'text-transform: uppercase;'};
  ${(props) => props.glitch && 'font-weight: bold;'};

  font-size: 17px;

  .container {
    background: ${(props) => (props.glitch ? 'black' : '#f2f2f2')};
  }

  a:hover {
    ${(props) => props.glitch && "font-family: 'ISONIAN';"}
  }
`;

export default App;
