import lechat from '../images/lechat.png';
import glitchKitty from '../images/WIH/lechat.gif';
import bubble from '../images/bubble.png';
import glitchBubble from '../images/WIH/bubble.gif';
import styled from 'styled-components';
import React from 'react';
import { Link } from 'react-router-dom';

const UnstyledCat = ({
  className,
  glitch,
}: {
  className?: string;
  glitch?: boolean;
}) => <img src={glitch ? glitchKitty : lechat} className={className} />;

const Cat = styled(UnstyledCat)`
  width: 250px;
  position: absolute;
  bottom: 0;
  right: -27px;
`;

const UnstyledBubble = ({
  className,
  glitch,
}: {
  className?: string;
  glitch?: boolean;
}) => <img src={glitch ? glitchBubble : bubble} className={className} />;

const Bubble = styled(UnstyledBubble)`
  position: absolute;
  height: 300px;
  object-fit: cover;
  max-width: 100vw;
  overflow: hidden;
`;

const UnstyledText = ({
  className,
  glitch,
}: {
  className?: string;
  glitch?: boolean;
}) => (
  <div className={className}>
    <div className={'top'}>
      {glitch ? 'REPENT TO UR WEDDING LORDS' : 'WEDDING IS HAPPENING'}
    </div>
  </div>
);

const Text = styled(UnstyledText)`
  max-width: 445px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  .top {
    font-size: 55px;
    line-height: 52px;
    font-family: ${(props) => (props.glitch ? 'ARIAL' : 'Permanent Marker')};
  }
`;

const UnstyledSpeech = ({
  className,
  glitch,
}: {
  className?: string;
  glitch?: boolean;
}) => (
  <div className={className}>
    <Bubble glitch={glitch} />
    <Text glitch={glitch} />
  </div>
);

const Speech = styled(UnstyledSpeech)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 300px;
  overflow: hidden;
`;

const Container = ({
  className,
  glitch,
}: {
  className?: string;
  glitch?: boolean;
}) => {
  console.log(glitch);
  return (
    <div className={className}>
      <Link to={glitch ? '/' : '/WIH'}>
        <div className={'catContainer'}>
          <Speech glitch={glitch} />
          <Cat glitch={glitch} />
        </div>
      </Link>
    </div>
  );
};

const SaveTheDate = styled(Container)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > a {
    color: ${(props) => (props.glitch ? 'red' : 'black')};
    text-decoration: none;

    &:hover {
      font-weight: normal;
    }
  }

  .catContainer {
    text-align: center;
    position: relative;
    width: 100vw;
    max-width: 730px;
    flex-shrink: 1;
    height: 444px;
  }
`;

export default SaveTheDate;
