import SaveTheDate from './WIH';
import styled from 'styled-components';
import saury from '../images/saury.png';
import glitchSaury from '../images/WIH/saury.gif';
import React from 'react';
import { Section } from './formatting';
import Schedule from './schedule';
import Bouncer from './bouncer';

const SuaryContainer = styled.div<{ glitch?: boolean }>`
  position: relative;
  padding-left: 33px;
  padding-right: 33px;
  overflow: hidden;

  .sauryLeft {
    position: absolute;
    width: 300px;
    left: -33px;
    top: 100px;
    opacity: ${(props) => (props.glitch ? 1 : 0.1)};

    &.secondRow {
      top: 1600px;
    }
  }

  .sauryRight {
    position: absolute;
    width: 300px;
    right: -33px;
    top: 750px;
    opacity: ${(props) => (props.glitch ? 1 : 0.1)};
    transform: scale(-1, -1);

    &.secondRow {
      top: 2350px;
    }
  }
`;

const UnstyledMain = ({
  className,
  glitch,
}: {
  className?: string;
  glitch?: boolean;
}) => (
  <div className={className}>
    {glitch && <Bouncer />}
    <SaveTheDate glitch={glitch} />
    <SuaryContainer glitch={glitch}>
      <img className="sauryLeft" src={glitch ? glitchSaury : saury} />
      <img className="sauryRight" src={glitch ? glitchSaury : saury} />
      {/* <img className="sauryLeft secondRow" src={saury} />
      <img className="sauryRight secondRow" src={saury} /> */}
      <div className="sections">
        <Section id="schedule">
          <Schedule glitch={glitch} />
        </Section>
      </div>
    </SuaryContainer>
  </div>
);

const Main = styled(UnstyledMain)`
  .sections {
    position: relative;
  }

  p {
    margin-left: 15px;
  }
`;

export default Main;
