import styled from 'styled-components';
import React from 'react';
import WIH from '../images/WIH/WIHglitch.gif';

const BouncerUnstyled = ({ className }: { className?: string }) => (
  <div className={className}>
    <div className={'x'}>
      <img className="y" src={WIH} />
    </div>
  </div>
);

const Bouncer = styled(BouncerUnstyled)`
  width: 0;
  height: 0;

  .x,
  .y {
    width: 200px;
    height: 200px;
  }

  .x {
    animation: x 13s linear infinite alternate;
  }

  .y {
    animation: y 7s linear infinite alternate;
  }

  @keyframes x {
    100% {
      transform: translateX(calc(100vw - 200px));
    }
  }

  @keyframes y {
    100% {
      transform: translateY(calc(100vh - 200px));
    }
  }
`;

export default Bouncer;
