import styled from 'styled-components';
import React from 'react';
import { H1, H2, H3, H4, H5 } from './formatting';

const UnstyledCovid = ({
  className,
  glitch,
}: {
  className?: string;
  glitch?: boolean;
}) => (
  <div className={className}>
    <div className="boxsu">
      <H3 glitch={glitch}>Covid Considerations</H3>
      <p>
        {glitch ? 'UR WEDDING LORDS' : 'We'} require that all guests be current
        with vaccinations including recommended booster doses.
      </p>
      <p>
        {glitch ? 'UR WEDDING LORDS' : 'We'} will require proof of a negative
        rapid covid test before each event.
      </p>
    </div>
  </div>
);

const Covid = styled(UnstyledCovid)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & .boxsu {
    border: 3px solid ${(props) => (props.glitch ? 'blue' : '#39738f')};
    border-radius: 3px;
    padding: 11px 33px;
    padding-top: 0;
    width: fit-content;
    max-width: 500px;
    flex-shrink: 0;
    margin-bottom: 33px;
    margin-top: 33px;
  }
`;

const Day = styled.div`
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 45%;
  min-width: 300px;
  max-width: 500px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
  margin-right: 15px;
`;

const UnstyledSchedule = ({
  className,
  glitch,
}: {
  className?: string;
  glitch?: boolean;
}) => (
  <div className={className}>
    <H1 glitch={glitch}>Schedule of Events</H1>
    <Day>
      <div>
        <H2 glitch={glitch}>Friday, September 23rd</H2>
        <H3 glitch={glitch}>
          Celebration at <b>Spoke Wine Bar</b>
        </H3>
        <p>89 Holland St, Somerville, MA 02144</p>
        <H5 glitch={glitch}>4pm: cocktail hour</H5>
        <p>
          Arrive between 4:00 and 5:00 to say hello. Drinks and snacks will be
          available.
        </p>
        <p>
          <i>Tasting notes: aged cheese, cured meats, new greets </i>
        </p>
        <H5 glitch={glitch}>5pm - 9pm: reception</H5>
        <p>
          Celebrate our wedding with {glitch ? 'UR WEDDING LORDS' : 'us'}.
          Dinner will be served cocktail-style starting at 5:00 and{' '}
          {glitch ? 'UR WEDDING LORDS' : 'we'} will wrap up before 9:00 with a
          Pittsburgh cookie table.
        </p>
        <p>
          <i>Tasting notes: endless supper, toast, math </i>
        </p>
        <H5 glitch={glitch}>9pm - midnight: afterparty</H5>
        <p>Stay out late and continue the celebration. </p>
        <p>
          <i>Tasting notes: shenanigans, hijinks, zzz </i>
        </p>

        <H4 glitch={glitch}>Dress code</H4>
        <p>
          {glitch ? 'UR WEDDING LORDS' : 'We'} encourage you to wear something
          that makes you feel fancy or stylish, no matter the formality.{' '}
          {glitch ? 'UR WEDDING LORDS' : 'We'} are happy for this celebration to
          provide an excuse to wear something you want to wear, but wouldn’t
          otherwise.
        </p>
        <p>
          {glitch ? 'UR WEDDING LORDS' : 'We'} will be wearing fancy clothes,
          but not traditional wedding attire.
        </p>
      </div>
    </Day>
    <Day>
      <div>
        <H2 glitch={glitch}>Saturday, September 24th</H2>
        <H3 glitch={glitch}>
          Brunch at <b>Juliet</b>
        </H3>
        <p>21 Union Square, Somerville, MA 02143</p>
        <H5 glitch={glitch}>10:30am: brunch</H5>

        <H4 glitch={glitch}>Dress code</H4>
        <p>
          {glitch ? 'UR WEDDING LORDS' : 'We'} encourage you to wear whatever
          will allow you to eat the most toast.
        </p>
      </div>
      <Covid glitch={glitch} />
    </Day>
  </div>
);

const Schedule = styled(UnstyledSchedule)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export default Schedule;
