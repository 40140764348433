import styled from 'styled-components';

export const Section = styled.div<{ glitch?: boolean }>`
  padding: 20px 33px 20px 33px;

  p {
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 16px;

    i {
      font-size: 14px;
    }
  }

  a {
    color: #39738f;

    &:hover {
      font-weight: bold;
    }
  }
`;

export const H1 = styled.h1<{ glitch?: boolean }>`
  font-size: 55px;
  line-height: 52px;
  color: ${(props) => (props.glitch ? 'white' : '#131313')};
  ${(props) => props.glitch && 'font-family: ISONIAN'};

  margin: 0 0 23px;
  width: 100%;
  text-align: center;
  padding-top: 33px;
`;

export const H2 = styled.h2<{ glitch?: boolean }>`
  font-size: 24px;
  color: ${(props) => (props.glitch ? 'blue' : '#131313')};
`;

export const H3 = styled.h3<{ glitch?: boolean }>`
  margin-bottom: 3px;
  color: ${(props) => (props.glitch ? 'white' : '#131313')};
  ${(props) => props.glitch && 'font-family: ISONIAN'};

  b {
    ${(props) => props.glitch && 'color: red'};
  }
`;

export const H4 = styled.h4<{ glitch?: boolean }>`
  margin-bottom: 3px;
  color: ${(props) => (props.glitch ? 'blue' : '#131313')};
`;

export const H5 = styled.h5<{ glitch?: boolean }>`
  color: ${(props) => (props.glitch ? 'blue' : '#131313')};
  font-size: 18px;
  margin-bottom: 3px;
  margin-top: 15px;
`;
